import * as Ably from "ably/promises";
import {Types} from "ably/promises";
import bellUrl from "./bell.mp3"


interface LocalMessage {
    type: "local",
    key: string;
}

interface ResetMessage {
    type: "reset",
}

interface RemoteMessage {
    type: "remote";
}

export type Message = RemoteMessage | LocalMessage | ResetMessage;

let audio;

export const registerAudio = () => {
    audio = new Audio(bellUrl);
}

export const playBell = () => {
    audio.play();
}

export const registerClient = async (messageCallback: (message: Message) => void) => {
    const optionalClientId = "optionalClientId"; // When not provided in authUrl, a default will be used.
    const ably = new Ably.Realtime.Promise({ authUrl: `/api/ably-token-request?clientId=${optionalClientId}` });
    const channel = ably.channels.get("some-channel-name");

    await channel.subscribe((msg: Types.Message) => {
        console.log("Ably message received", msg);
        const {type, key} = msg.data;
        messageCallback({key, type})
    });

    return channel;
}

let locked = false;
export const handleMessage = (msg: Message)  => {
    const localKeys = {
        "ArrowUp": "🇫🇮",
        "ArrowRight": "🇮🇹",
        "ArrowLeft": "🇮🇳",
        "ArrowDown": "🇧🇷"
    };

    if (msg.type === "reset") {
        document.getElementById("buzzed").innerText = "";
        document.getElementsByTagName("body")[0].style.backgroundColor = "white";
        locked = false;
        return;
    }
    if (locked){
        return;
    }
    locked = true;

    if (msg.type === "remote") {
        playBell();
        document.getElementsByTagName("body")[0].style.backgroundColor = "lightblue";
        document.getElementById("buzzed").innerText = "📱";
    }
    else if (Object.keys(localKeys) .includes(msg.key)){
        playBell();
        document.getElementsByTagName("body")[0].style.backgroundColor = "lightblue";
        document.getElementById("buzzed").innerText = localKeys[msg.key]
    }

}
